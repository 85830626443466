@layer application-resets {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  img,
  picture,
  video,
  canvas,
  svg {
    display: block;
    max-width: 100%;
  }

  body,
  html {
    margin: 0px;
    padding: 0px;
  }

  html {
    scrollbar-width: thin;
  }

  body {
    margin: 0px;
    min-height: calc(100svh - var(--res-scrollbar-width));
  }

  button {
    border-width: 0px;
  }

  p {
    margin: 0;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
  }
}
