@use '../fonts/r1-icons/r1-icons.css';

.r1-icon {
  &.size-xs {
    font-size: 0.5rem;
  }
  &.size-sm {
    font-size: 0.75rem;
  }
  &.size-md {
    font-size: 1rem;
  }
  &.size-lg {
    font-size: 1.25rem;
  }
  &.size-xl {
    font-size: 1.5rem;
  }
  &.size-xxl {
    font-size: 2rem;
  }
}
