@layer primeng-resets {
  .info-icon,
  .info-icon-large {
    font-size: 1.125rem;
    color: theme(colors.r1-blue.800);
  }

  .p-inputnumber,
  .p-inputnumber-input {
    inline-size: 100%;
  }

  .p-select,
  .p-multiselect,
  .p-autocomplete {
    inline-size: 100%;
  }

  .p-select-label {
    align-content: center;
  }

  .p-datatable-table-container {
    scrollbar-width: thin;
  }

  .p-toast-summary:empty {
    display: none;
  }

  /* PrimeNg design tokens don't give capability to style
  root level of items of the menubar component. So we have to place overrides
  here. Once PrimeNg evolve their design system, these rules should be removed
  and replaced with tokens (see primeng-theme.ts file) */
  .p-menubar-root-list > li > .p-menubar-item-content {
    color: theme(colors.white);
  }

  .p-menubar-root-list > li > .p-menubar-item-content:hover,
  .p-menubar-root-list > .p-menubar-item-active > .p-menubar-item-content {
    background-color: theme(colors.r1-blue.900/90);
  }
}
