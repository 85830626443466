/*
  TODO (gorodnichev): The whole file should be removed once we get
  ag-grid company wide theme.
*/
.ag-theme-material {
  --ag-icon-font-code-filter: '\f138';
}

.ag-body-horizontal-scroll-viewport {
  scrollbar-width: thin;
}
