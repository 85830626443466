@font-face {
  font-family: 'r1-shared-ui-library-icons';
  src:  url('fonts/r1-shared-ui-library-icons.eot?s44zhk');
  src:  url('fonts/r1-shared-ui-library-icons.eot?s44zhk#iefix') format('embedded-opentype'),
    url('fonts/r1-shared-ui-library-icons.ttf?s44zhk') format('truetype'),
    url('fonts/r1-shared-ui-library-icons.woff?s44zhk') format('woff'),
    url('fonts/r1-shared-ui-library-icons.svg?s44zhk#r1-shared-ui-library-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.r1-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'r1-shared-ui-library-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.r1-icon-grip .path1:before {
  content: "\e900";
  color: rgb(255, 255, 255);
}
.r1-icon-grip .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(8, 22, 38);
}
.r1-icon-grip .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(8, 22, 38);
}
.r1-icon-account-store-light:before {
  content: "\e903";
}
.r1-icon-account-store-solid:before {
  content: "\e904";
}
.r1-icon-ag-filter-fill-solid:before {
  content: "\e905";
}
.r1-icon-ag-filter-light:before {
  content: "\e906";
}
.r1-icon-alert-semi-filled:before {
  content: "\e907";
}
.r1-icon-alert-solid:before {
  content: "\e908";
}
.r1-icon-all-work:before {
  content: "\e909";
}
.r1-icon-analytics-admin-solid:before {
  content: "\e90a";
}
.r1-icon-analytics-metrics .path1:before {
  content: "\e90b";
  color: rgb(0, 140, 149);
}
.r1-icon-analytics-metrics .path2:before {
  content: "\e90c";
  margin-left: -0.9443359375em;
  color: rgb(29, 187, 180);
}
.r1-icon-analytics-metrics .path3:before {
  content: "\e90d";
  margin-left: -0.9443359375em;
  color: rgb(223, 246, 246);
}
.r1-icon-analytics-planning:before {
  content: "\e90e";
}
.r1-icon-analytics-solid:before {
  content: "\e90f";
}
.r1-icon-angle-down-light:before {
  content: "\e910";
}
.r1-icon-angle-left-light:before {
  content: "\e911";
}
.r1-icon-angle-right-light:before {
  content: "\e912";
}
.r1-icon-angle-up-light:before {
  content: "\e913";
}
.r1-icon-arrow-down-light:before {
  content: "\e914";
}
.r1-icon-arrow-left-light:before {
  content: "\e915";
}
.r1-icon-arrow-right-light:before {
  content: "\e916";
}
.r1-icon-arrows-rotate:before {
  content: "\e917";
}
.r1-icon-arrow-up-light:before {
  content: "\e918";
}
.r1-icon-arrow-up-trend-solid:before {
  content: "\e919";
}
.r1-icon-book-fill-solid:before {
  content: "\e91a";
}
.r1-icon-book-light:before {
  content: "\e91b";
}
.r1-icon-bookmark-fill-solid:before {
  content: "\e91c";
}
.r1-icon-bookmark-light:before {
  content: "\e91d";
}
.r1-icon-box-check-solid:before {
  content: "\e91e";
}
.r1-icon-calendar-light:before {
  content: "\e91f";
}
.r1-icon-cdm-table:before {
  content: "\e920";
}
.r1-icon-charge-cat:before {
  content: "\e921";
}
.r1-icon-chart-area:before {
  content: "\e922";
}
.r1-icon-chart-bar:before {
  content: "\e923";
}
.r1-icon-chart-column:before {
  content: "\e924";
}
.r1-icon-chart-line:before {
  content: "\e925";
}
.r1-icon-chart-pie:before {
  content: "\e926";
}
.r1-icon-check-light:before {
  content: "\e927";
}
.r1-icon-chevron-down-light:before {
  content: "\e928";
}
.r1-icon-chevron-left-light:before {
  content: "\e929";
}
.r1-icon-chevron-right-light:before {
  content: "\e92a";
}
.r1-icon-chevron-square-down-light:before {
  content: "\e92b";
}
.r1-icon-chevron-square-left-light:before {
  content: "\e92c";
}
.r1-icon-chevron-square-right-light:before {
  content: "\e92d";
}
.r1-icon-chevron-square-up-light:before {
  content: "\e92e";
}
.r1-icon-chevron-up-light:before {
  content: "\e92f";
}
.r1-icon-clear-filter-light:before {
  content: "\e930";
}
.r1-icon-clients:before {
  content: "\e931";
}
.r1-icon-clone-solid:before {
  content: "\e932";
}
.r1-icon-cloud:before {
  content: "\e933";
}
.r1-icon-cloud-x-mark:before {
  content: "\e934";
}
.r1-icon-column-reset-light:before {
  content: "\e935";
}
.r1-icon-columns-light:before {
  content: "\e936";
}
.r1-icon-contracts:before {
  content: "\e937";
}
.r1-icon-contracts-light:before {
  content: "\e938";
}
.r1-icon-csv-file-light:before {
  content: "\e939";
}
.r1-icon-dashboard:before {
  content: "\e93a";
}
.r1-icon-delete-light:before {
  content: "\e93b";
}
.r1-icon-disable-light:before {
  content: "\e93c";
}
.r1-icon-dollar:before {
  content: "\e93d";
}
.r1-icon-dollar-circle-fill-solid:before {
  content: "\e93e";
}
.r1-icon-download-light:before {
  content: "\e93f";
}
.r1-icon-drg-base-pay-factor:before {
  content: "\e940";
}
.r1-icon-edit-light:before {
  content: "\e941";
}
.r1-icon-ellipsis-horizontal-light:before {
  content: "\e942";
}
.r1-icon-ellipsis-vertical-light:before {
  content: "\e943";
}
.r1-icon-envelope:before {
  content: "\e944";
}
.r1-icon-envelope-fill-solid:before {
  content: "\e945";
}
.r1-icon-error:before {
  content: "\e946";
}
.r1-icon-excel-file-light:before {
  content: "\e947";
}
.r1-icon-external-link-light:before {
  content: "\e948";
}
.r1-icon-fedex-brand:before {
  content: "\e949";
}
.r1-icon-file-light:before {
  content: "\e94a";
}
.r1-icon-filter-fill-solid:before {
  content: "\e94b";
}
.r1-icon-filter-light:before {
  content: "\e94c";
}
.r1-icon-filter-multi-light:before {
  content: "\e94d";
}
.r1-icon-function:before {
  content: "\e94e";
}
.r1-icon-guage-simple-solid:before {
  content: "\e94f";
}
.r1-icon-hide-light:before {
  content: "\e950";
}
.r1-icon-history-light:before {
  content: "\e951";
}
.r1-icon-hospital-solid:before {
  content: "\e952";
}
.r1-icon-info-light:before {
  content: "\e953";
}
.r1-icon-library:before {
  content: "\e954";
}
.r1-icon-line-height-light:before {
  content: "\e955";
}
.r1-icon-lock-keyhole:before {
  content: "\e956";
}
.r1-icon-lock-keyhole-fill-solid:before {
  content: "\e957";
}
.r1-icon-logo .path1:before {
  content: "\e958";
  color: rgb(51, 66, 87);
}
.r1-icon-logo .path2:before {
  content: "\e959";
  margin-left: -7.3603515625em;
  color: rgb(51, 66, 87);
}
.r1-icon-logo .path3:before {
  content: "\e95a";
  margin-left: -7.3603515625em;
  color: rgb(51, 66, 87);
}
.r1-icon-logo .path4:before {
  content: "\e95b";
  margin-left: -7.3603515625em;
  color: rgb(51, 66, 87);
}
.r1-icon-logo .path5:before {
  content: "\e95c";
  margin-left: -7.3603515625em;
  color: rgb(51, 66, 87);
}
.r1-icon-logo .path6:before {
  content: "\e95d";
  margin-left: -7.3603515625em;
  color: rgb(51, 66, 87);
}
.r1-icon-logo .path7:before {
  content: "\e95e";
  margin-left: -7.3603515625em;
  color: rgb(51, 66, 87);
}
.r1-icon-logo .path8:before {
  content: "\e95f";
  margin-left: -7.3603515625em;
  color: rgb(51, 66, 87);
}
.r1-icon-logo .path9:before {
  content: "\e960";
  margin-left: -7.3603515625em;
  color: rgb(51, 66, 87);
}
.r1-icon-logo .path10:before {
  content: "\e961";
  margin-left: -7.3603515625em;
  color: rgb(18, 189, 198);
}
.r1-icon-logo .path11:before {
  content: "\e962";
  margin-left: -7.3603515625em;
  color: rgb(98, 75, 120);
}
.r1-icon-logo .path12:before {
  content: "\e963";
  margin-left: -7.3603515625em;
  color: rgb(51, 66, 87);
}
.r1-icon-logo .path13:before {
  content: "\e964";
  margin-left: -7.3603515625em;
  color: rgb(0, 140, 149);
}
.r1-icon-medicaid:before {
  content: "\e965";
}
.r1-icon-medicare:before {
  content: "\e966";
}
.r1-icon-medicare-light:before {
  content: "\e967";
}
.r1-icon-menu-light:before {
  content: "\e968";
}
.r1-icon-microsoft-teams .path1:before {
  content: "\e969";
  color: rgb(80, 89, 201);
}
.r1-icon-microsoft-teams .path2:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(123, 131, 235);
}
.r1-icon-microsoft-teams .path3:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}
.r1-icon-microsoft-teams .path4:before {
  content: "\e96c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.2;
}
.r1-icon-microsoft-teams .path5:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.2;
}
.r1-icon-microsoft-teams .path6:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.2;
}
.r1-icon-microsoft-teams .path7:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}
.r1-icon-microsoft-teams .path8:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.2;
}
.r1-icon-microsoft-teams .path9:before {
  content: "\e971";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.2;
}
.r1-icon-microsoft-teams .path10:before {
  content: "\e972";
  margin-left: -1em;
  color: rgb(77, 85, 189);
}
.r1-icon-microsoft-teams .path11:before {
  content: "\e973";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.r1-icon-minus-light:before {
  content: "\e974";
}
.r1-icon-my-work:before {
  content: "\e975";
}
.r1-icon-notification-fill-solid:before {
  content: "\e976";
}
.r1-icon-notification-light:before {
  content: "\e977";
}
.r1-icon-payers-solid:before {
  content: "\e978";
}
.r1-icon-payor-plan-map-light:before {
  content: "\e979";
}
.r1-icon-pdf-file-light:before {
  content: "\e97a";
}
.r1-icon-pie-chart-solid:before {
  content: "\e97b";
}
.r1-icon-plus-light:before {
  content: "\e97c";
}
.r1-icon-powerbi .path1:before {
  content: "\e97d";
  color: rgb(235, 187, 20);
}
.r1-icon-powerbi .path2:before {
  content: "\e97e";
  margin-left: -1em;
  color: rgb(222, 152, 0);
}
.r1-icon-powerbi .path3:before {
  content: "\e97f";
  margin-left: -1em;
  color: rgb(243, 205, 50);
}
.r1-icon-question-circle-light:before {
  content: "\e980";
}
.r1-icon-r1bi-training .path1:before {
  content: "\e981";
  color: rgb(244, 144, 12);
}
.r1-icon-r1bi-training .path2:before {
  content: "\e982";
  margin-left: -1em;
  color: rgb(204, 214, 221);
}
.r1-icon-r1bi-training .path3:before {
  content: "\e983";
  margin-left: -1em;
  color: rgb(225, 232, 237);
}
.r1-icon-r1bi-training .path4:before {
  content: "\e984";
  margin-left: -1em;
  color: rgb(244, 144, 12);
}
.r1-icon-r1bi-training .path5:before {
  content: "\e985";
  margin-left: -1em;
  color: rgb(255, 172, 51);
}
.r1-icon-r1bi-training .path6:before {
  content: "\e986";
  margin-left: -1em;
  color: rgb(244, 144, 12);
}
.r1-icon-r1-logo:before {
  content: "\e987";
  color: #0047bb;
}
.r1-icon-remove-light:before {
  content: "\e988";
}
.r1-icon-remove-slash-light:before {
  content: "\e989";
}
.r1-icon-reports:before {
  content: "\e98a";
}
.r1-icon-reset-columns-light:before {
  content: "\e98b";
}
.r1-icon-reset-light:before {
  content: "\e98c";
}
.r1-icon-roles:before {
  content: "\e98d";
}
.r1-icon-roles-solid:before {
  content: "\e98e";
}
.r1-icon-rules:before {
  content: "\e98f";
}
.r1-icon-rules-light:before {
  content: "\e990";
}
.r1-icon-rush-fill-solid:before {
  content: "\e991";
}
.r1-icon-rush-light:before {
  content: "\e992";
}
.r1-icon-save-light:before {
  content: "\e993";
}
.r1-icon-search-fill-solid:before {
  content: "\e994";
}
.r1-icon-search-light:before {
  content: "\e995";
}
.r1-icon-sequence:before {
  content: "\e996";
}
.r1-icon-setting-light:before {
  content: "\e997";
}
.r1-icon-sharepoint .path1:before {
  content: "\e998";
  color: rgb(3, 108, 112);
}
.r1-icon-sharepoint .path2:before {
  content: "\e999";
  margin-left: -1.0234375em;
  color: rgb(26, 155, 161);
}
.r1-icon-sharepoint .path3:before {
  content: "\e99a";
  margin-left: -1.0234375em;
  color: rgb(55, 198, 208);
}
.r1-icon-sharepoint .path4:before {
  content: "\e99b";
  margin-left: -1.0234375em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}
.r1-icon-sharepoint .path5:before {
  content: "\e99c";
  margin-left: -1.0234375em;
  color: rgb(0, 0, 0);
  opacity: 0.2;
}
.r1-icon-sharepoint .path6:before {
  content: "\e99d";
  margin-left: -1.0234375em;
  color: rgb(0, 0, 0);
  opacity: 0.2;
}
.r1-icon-sharepoint .path7:before {
  content: "\e99e";
  margin-left: -1.0234375em;
  color: rgb(0, 0, 0);
  opacity: 0.2;
}
.r1-icon-sharepoint .path8:before {
  content: "\e99f";
  margin-left: -1.0234375em;
  color: rgb(5, 143, 146);
}
.r1-icon-sharepoint .path9:before {
  content: "\e9a0";
  margin-left: -1.0234375em;
  color: rgb(255, 255, 255);
}
.r1-icon-show-light:before {
  content: "\e9a1";
}
.r1-icon-skipped:before {
  content: "\e9a2";
}
.r1-icon-sort-ascending-light:before {
  content: "\e9a3";
}
.r1-icon-sort-decending-light:before {
  content: "\e9a4";
}
.r1-icon-spinner-duotone .path1:before {
  content: "\e9a5";
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.r1-icon-spinner-duotone .path2:before {
  content: "\e9a6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.r1-icon-split:before {
  content: "\e9a7";
}
.r1-icon-star-fill-solid:before {
  content: "\e9a8";
}
.r1-icon-star-light:before {
  content: "\e9a9";
}
.r1-icon-success-solid:before {
  content: "\e9aa";
}
.r1-icon-table:before {
  content: "\e9ab";
}
.r1-icon-table-cells-large:before {
  content: "\e9ac";
}
.r1-icon-thumbtack-fill-solid:before {
  content: "\e9ad";
}
.r1-icon-thumbtack-light:before {
  content: "\e9ae";
}
.r1-icon-tiles-fill-solid:before {
  content: "\e9af";
}
.r1-icon-tiles-light:before {
  content: "\e9b0";
}
.r1-icon-undo-light:before {
  content: "\e9b1";
}
.r1-icon-upload-light:before {
  content: "\e9b2";
}
.r1-icon-ups-brand:before {
  content: "\e9b3";
}
.r1-icon-user-chart-solid:before {
  content: "\e9b4";
}
.r1-icon-users:before {
  content: "\e9b5";
}
.r1-icon-user-solid:before {
  content: "\e9b6";
}
.r1-icon-usps-brand:before {
  content: "\e9b7";
}
.r1-icon-word-file-light:before {
  content: "\e9b8";
}
.r1-icon-workflow:before {
  content: "\e9b9";
}
.r1-icon-work-history:before {
  content: "\e9ba";
}
